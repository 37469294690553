//
//  Solid Colors
//  ---------------------------------------------
//

$theme-blue-light1: #5a6780 !default;
$theme-blue-light2: #8799be !default;
$theme-blue-light3: #525a6a !default;
$theme-blue: #5070a9 !default;
$theme-blue-dark1: #212739 !default;
$theme-blue-dark2: #121622 !default;
$theme-blue-dark3: #0b0d15 !default;
$theme-blue-dark4: #4d5464 !default;

$theme-green: #00b67a !default;
$theme-pink: #cb4666 !default;
$theme-orange: #ff8a00 !default;

$theme-white: #ffffff !default;
$theme-off-white: #f4f7f8 !default;
$theme-grey-light: #e5e5e5 !default;
$theme-grey: #abb0b6 !default;
$theme-grey-dark: #858a8e !default;
$theme-grey-dark2: #999ca6 !default;
$theme-grey-dark3: #f3f3f3 !default;
$theme-grey-dark4: #858a8e !default;

//
//  Gradient Colors
//  ---------------------------------------------
//

$theme-gradient-blue1: linear-gradient(73.84deg, #3b4e76 4.51%, #20293f 36.88%, #111521 65.69%);
$theme-gradient-blue2: linear-gradient(73.84deg, #3b4e76 4.51%, #111521 74.17%) !default;
$theme-gradient-blue3: linear-gradient(
    75.07deg,
    #2d3b5a 0%,
    #171d2d 49.63%,
    #111521 99.69%
) !default;
$theme-gradient-green: linear-gradient(180deg, #366764 0%, #101b1e 100%) !default;
$theme-gradient-purple: linear-gradient(180deg, #673662 0%, #221222 100%) !default;
$theme-gradient-red: linear-gradient(180deg, #cb4666 0%, #681428 100%) !default;

// Dark Mode
$theme-box-gradient-blue-dark: linear-gradient(180deg, #3b4e76 -5.95%, #111521 90.27%) !default;
$theme-box-gradient-green-dark: linear-gradient(210.28deg, #366764 1.39%, #101b1e 74.63%) !default;
$theme-box-gradient-purple-dark: linear-gradient(
    148.61deg,
    #673662 -0.46%,
    #221222 86.38%
) !default;
$theme-gradient-black-fade: linear-gradient(
    0deg,
    #0b0d15 2.93%,
    rgba(11, 13, 21, 0) 96.63%
) !default;

// Light Mode
$theme-box-gradient-blue-light: linear-gradient(180deg, #7b9ee9 -5.95%, #3b4e76 90.27%) !default;
$theme-box-gradient-green-light: linear-gradient(180deg, #84cac6 -5.95%, #366764 90.27%) !default;
$theme-box-gradient-purple-light: linear-gradient(
    148.61deg,
    #cf72c6 -0.46%,
    #673662 86.38%
) !default;
$theme-gradient-white-fade: linear-gradient(
    0deg,
    #f4f7f8 2.93%,
    rgba(244, 247, 248, 0) 96.63%
) !default;
$theme-gradient-white-fade2: linear-gradient(
    180deg,
    rgba(11, 13, 21, 0.06) 0.42%,
    #f4f7f8 99.63%
) !default;

// Buttons
$theme-gradient-button-blue: linear-gradient(180deg, #7698d3 0%, #5070a9 100%) !default;
$theme-gradient-button-blue-hover: linear-gradient(180deg, #99b7eb 0%, #5070a9 100%) !default;
$theme-gradient-button-green: linear-gradient(180deg, #50e2b2 0%, #00b67a 100%) !default;
$theme-gradient-button-green-hover: linear-gradient(180deg, #7bfbd1 0%, #00b67a 100%) !default;

//
//  Light/Dark Modes
//  ---------------------------------------------
//

@mixin mode-dark {
    // Specific
    --color-logo: #{$theme-white};
    --color-header-bg: transparent;
    --color-trustpilot: #{$theme-green};
    --color-header-icon: #{$theme-blue-light1};
    --color-header-icon-hover: #{$theme-white};
    --color-menu-background: #{$theme-blue-dark3};
    --color-checkout-background: none;
    --color-scrollbar-track: #{$theme-blue-dark2};
    --color-scrollbar-thumb: #{$theme-blue-light3};
    --color-checkout-message-info-background: #{$theme-blue-dark4};
    --color-checkout-message-info: #{$theme-white};

    // Generic
    --color-blue-primary: #{$theme-blue-dark3};
    --color-blue-primary-reverse: #{$theme-blue};
    --color-blue-secondary: #{$theme-blue-dark3};
    --color-blue-secondary-reverse: #{$theme-white};
    --color-blue-secondary2: #{$theme-blue-dark3};
    --color-blue-secondary3: #{$theme-blue-dark3};
    --color-blue-tertiary: #{$theme-blue-dark2};
    --color-blue-tertiary2: #{$theme-blue-dark2};
    --color-blue-tertiary3: #{$theme-blue-dark3};
    --color-text1: #{$theme-blue-light2};
    --color-text2: #{$theme-blue-light2};
    --color-text3: #{$theme-blue-light2};
    --color-text4: #{$theme-blue-light2};
    --color-text5: #{$theme-white};
    --color-text6: #{$theme-blue-light2};
    --color-text7: #{$theme-grey};
    --color-text8: #{$theme-white};
    --color-border: #{$theme-blue-dark1};
    --color-border2: rgba(255, 255, 255, 0.1);
    --color-border3: #{$theme-blue-dark1};
    --color-border4: rgba(255, 255, 255, 0.1);
    --color-border5: #{$theme-white};
    --color-border6: #{$theme-blue-light2};
    --color-border7: #3b4e76;
    --color-border8: #23252c;
    --color-border9: #3a3d48;
    --bg-color-edit: rgba(255, 255, 255, 0.1);
    --color-input: #{$theme-blue-dark1};
    --color-input-reverse: #{$theme-grey-light};
    --color-input2: #{$theme-white};
    --color-input3: #{$theme-blue-dark1};
    --color-input4: rgba(255, 255, 255, 0.1);
    --color-input-search: #{$theme-white};
    --color-icon: #{$theme-white};
    --brightness-light: brightness(1);
    --invert-light: invert(0);
    --transparent: rgba(0, 0, 0, 0);
    --transparent2: rgba(11, 13, 21, 0);

    // Gradients
    --gradient-primary: #{$theme-gradient-blue1};
    --gradient-secondary: #{$theme-gradient-blue3};
    --gradient-fade: #{$theme-gradient-black-fade};
    --gradient-fade2: none;

    --box-gradient-blue: #{$theme-box-gradient-blue-dark};
    --box-gradient-green: #{$theme-box-gradient-green-dark};
    --box-gradient-purple: #{$theme-box-gradient-purple-dark};

    // Images
    --arrow-left-primary: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.902 1L1.605 6.296m5.315 5.112L1 5.488m.92.805h15' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
    --arrow-right-primary: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.018 11.408l5.296-5.297M10 1l5.92 5.92M15 6.115H0' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
    --chevron-down-primary: url(../images/icons/white/chevron-down.svg);
    --chevron-down-secondary: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 3 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.071l7.071 7.071 7.071-7.071' stroke='%23ffffff' stroke-width='2'/%3E%3C/svg%3E");
    --icon-x-primary: url(../images/icons/white/x.svg);
    --icon-x-secondary: url(../images/icons/blue/x2.svg);
    --icon-plus-primary: url(../images/icons/white/plus.svg);
    --icon-plus-primary2: url(../images/icons/white/plus3.svg);
    --icon-plus-secondary: url(../images/icons/blue/plus.svg);
    --icon-list-tick: url(../images/icons/white/list-tick.svg);
    --icon-save-cart: url(../images/icons/white/save-cart.svg);

    //gradient images
    --gradient-image-account: url('../images/gradients/dark.png');

    //offers page
    --color-offer: #ffffff;
    --color-bg-offer: rgba(255, 255, 255, 0.1);
    --color-bg-tag: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));

    //checkout process bar
    --bg-processbar: rgba(255, 255, 255, 0.1);
    --color-processbar: #fff;
    --icon-overview: url(../images/icons/white/overview.png);
    --icon-order: url("data:image/svg+xml,%3Csvg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.8L3.81481 1H15.0741L17.8889 4.8M1 4.8V18.1C1 18.6039 1.19771 19.0872 1.54963 19.4435C1.90155 19.7998 2.37885 20 2.87654 20H16.0123C16.51 20 16.9873 19.7998 17.3393 19.4435C17.6912 19.0872 17.8889 18.6039 17.8889 18.1V4.8M1 4.8H17.8889M13.1975 8.6C13.1975 9.60782 12.8021 10.5744 12.0983 11.287C11.3944 11.9996 10.4398 12.4 9.44444 12.4C8.44906 12.4 7.49445 11.9996 6.79061 11.287C6.08677 10.5744 5.69136 9.60782 5.69136 8.6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-return: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 7L1 12M1 12L6 17M1 12H13C14.0609 12 15.0783 11.5786 15.8284 10.8284C16.5786 10.0783 17 9.06087 17 8V1' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-rewards: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-download: url("data:image/svg+xml,%3Csvg width='21' height='17' viewBox='0 0 21 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.04966 12.6688L10.5022 16M10.5022 16L13.9547 12.6688M10.5022 16V8.50485M18.1668 13.5766C18.9172 13.0674 19.4799 12.3408 19.7733 11.5022C20.0667 10.6636 20.0755 9.75656 19.7984 8.91278C19.5214 8.06899 18.9728 7.33235 18.2324 6.80975C17.4921 6.28715 16.5983 6.00578 15.681 6.00647H14.5934C14.3338 5.03033 13.8481 4.12373 13.1728 3.35492C12.4975 2.58611 11.6503 1.97513 10.6948 1.56797C9.73941 1.16081 8.70069 0.968087 7.65689 1.0043C6.61309 1.04052 5.59141 1.30474 4.66876 1.77706C3.7461 2.24939 2.94653 2.91751 2.33022 3.73113C1.71391 4.54476 1.29693 5.48268 1.11068 6.47429C0.924421 7.4659 0.97374 8.48536 1.25492 9.45592C1.5361 10.4265 2.04182 11.3228 2.734 12.0775' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-wishlist: url("data:image/svg+xml,%3Csvg width='24' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.006 6.982l1 .028a1 1 0 000-.074l-1 .046zm10.708-3.036l-.874.485a1 1 0 001.749 0l-.875-.485zm10.708 3.036l-.999-.046a.979.979 0 000 .074l1-.028zM11.714 21l-.493.87a1 1 0 00.986 0l-.493-.87zM2.005 6.936A4.809 4.809 0 012 6.714H0c0 .105.002.21.007.314l1.998-.092zM2 6.714A4.714 4.714 0 016.714 2V0A6.714 6.714 0 000 6.714h2zM6.714 2c1.774 0 3.32.979 4.126 2.43l1.749-.97A6.713 6.713 0 006.714 0v2zm5.875 2.43A4.713 4.713 0 0116.714 2V0a6.713 6.713 0 00-5.874 3.46l1.749.97zM16.714 2a4.714 4.714 0 014.715 4.714h2A6.714 6.714 0 0016.713 0v2zm4.715 4.714c0 .075-.002.148-.006.222l1.998.092a6.94 6.94 0 00.008-.314h-2zm-.006.296c.004.133.006.272.006.419h2c0-.165-.003-.323-.007-.476l-2 .057zm.006.419c0 3.296-2.388 6.434-5.034 8.859a32.523 32.523 0 01-5.069 3.78l-.082.049a2.011 2.011 0 00-.019.01l-.004.003.493.87.494.87.003-.002.008-.005a2.644 2.644 0 00.124-.072 32.139 32.139 0 001.61-1.032 34.538 34.538 0 003.794-2.997c2.712-2.486 5.681-6.135 5.681-10.333h-2zM11.713 21a217.743 217.743 0 01.494-.87l-.005-.002c-.003-.003-.01-.006-.019-.011l-.082-.048a30.295 30.295 0 01-1.498-.96 32.528 32.528 0 01-3.571-2.821C4.388 13.863 2 10.725 2 7.428H0c0 4.199 2.97 7.848 5.681 10.334a34.529 34.529 0 005.404 4.029 11.692 11.692 0 00.124.072l.008.005.002.001h.001l.494-.869zM2 7.429c0-.147.002-.286.006-.419l-2-.057A23.97 23.97 0 000 7.43h2z' fill='white'/%3E%3C/svg%3E%0A");
    --icon-address-book: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33333 19V10H11.6667V19M1 7.3L9 1L17 7.3V17.2C17 17.6774 16.8127 18.1352 16.4793 18.4728C16.1459 18.8104 15.6937 19 15.2222 19H2.77778C2.30628 19 1.8541 18.8104 1.5207 18.4728C1.1873 18.1352 1 17.6774 1 17.2V7.3Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-account: url("data:image/svg+xml,%3Csvg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ccircle cx='8.33311' cy='4.68955' r='3.68955' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M15.8966 18.5253C15.8966 15.1377 12.5619 12.3914 8.44828 12.3914C4.33471 12.3914 1 15.1377 1 18.5253' stroke='white' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-payment: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5H18M2.54545 1H16.4545C17.3081 1 18 1.67157 18 2.5V11.5C18 12.3284 17.3081 13 16.4545 13H2.54545C1.69192 13 1 12.3284 1 11.5V2.5C1 1.67157 1.69192 1 2.54545 1Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-subcriptions: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2.75C19 1.7875 18.19 1 17.2 1H2.8C1.81 1 1 1.7875 1 2.75M19 2.75V13.25C19 14.2125 18.19 15 17.2 15H2.8C1.81 15 1 14.2125 1 13.25V2.75M19 2.75L10 8.875L1 2.75' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-store-credit: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.78711 9.96289L8.82617 11.0371C8.82617 11.5384 8.72526 11.9372 8.52344 12.2334H12.1025L12.0977 13H7.05859V12.2334H7.43457C7.56478 12.2041 7.67057 12.0837 7.75195 11.8721C7.83333 11.6572 7.87728 11.3936 7.88379 11.0811V11.042L7.84473 9.96289H7.03906V9.19629H7.82031L7.77637 7.92676C7.77637 7.27897 7.97493 6.76139 8.37207 6.37402C8.77246 5.98665 9.29818 5.79297 9.94922 5.79297C10.5677 5.79297 11.0576 5.96549 11.4189 6.31055C11.7803 6.6556 11.9609 7.11621 11.9609 7.69238H11.0283C11.0283 7.34408 10.9242 7.06901 10.7158 6.86719C10.5107 6.66536 10.2275 6.56445 9.86621 6.56445C9.52441 6.56445 9.24772 6.68978 9.03613 6.94043C8.82454 7.19108 8.71875 7.51986 8.71875 7.92676L8.7627 9.19629H10.3203V9.96289H8.78711Z' fill='white'/%3E%3Ccircle cx='9.5' cy='9.5' r='9' stroke='white'/%3E%3C/svg%3E%0A");
    --icon-download-stock: url("data:image/svg+xml,%3Csvg width='16' height='19' viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M13.1516 1H3C1.89543 1 1 1.89543 1 3V16.4536C1 17.5582 1.89543 18.4536 3 18.4536H10.7931C11.3189 18.4536 11.8235 18.2466 12.1978 17.8773L14.5563 15.5503C14.9372 15.1745 15.1516 14.6617 15.1516 14.1266V3C15.1516 1.89543 14.2562 1 13.1516 1Z' stroke='white'/%3E%3Cpath d='M11.3776 18.4536V16.6799C11.3776 15.5753 12.273 14.6799 13.3776 14.6799H15.1513' stroke='white'/%3E%3Cpath d='M4.06616 4.77378H11.8495' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M4.06616 7.13239H11.8495' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M4.06616 9.49093H11.8495' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M4.06616 11.8495H9.01922' stroke='white' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-quotes: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Crect x='0.5' y='0.5' width='17' height='17' rx='2.5' stroke='white'/%3E%3Cpath d='M0.5 12.5H6.5V17.5H3C1.61929 17.5 0.5 16.3807 0.5 15V12.5Z' stroke='white'/%3E%3Crect x='0.5' y='7.5' width='6' height='5' stroke='white'/%3E%3Cpath d='M12.5 7.5H17.5V15C17.5 16.3807 16.3807 17.5 15 17.5H12.5V7.5Z' stroke='white'/%3E%3Crect x='6.5' y='12.5' width='6' height='5' stroke='white'/%3E%3Crect x='6.5' y='7.5' width='6' height='5' stroke='white'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-my-save-cart: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H4.63636L7.07273 13.9049C7.15586 14.3486 7.38355 14.7471 7.71595 15.0308C8.04835 15.3145 8.46427 15.4652 8.89091 15.4565H17.7273C18.1539 15.4652 18.5698 15.3145 18.9022 15.0308C19.2346 14.7471 19.4623 14.3486 19.5455 13.9049L21 5.81884H5.54545M18.2549 18.7609C18.2549 19.4452 17.7282 20 17.0784 20C16.4287 20 15.902 19.4452 15.902 18.7609C15.902 18.0765 16.4287 17.5217 17.0784 17.5217C17.7282 17.5217 18.2549 18.0765 18.2549 18.7609ZM11.1961 18.7609C11.1961 19.4452 10.6694 20 10.0196 20C9.36986 20 8.84314 19.4452 8.84314 18.7609C8.84314 18.0765 9.36986 17.5217 10.0196 17.5217C10.6694 17.5217 11.1961 18.0765 11.1961 18.7609Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-logout: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-arrow-right: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.018 11.408l5.296-5.297M10 1l5.92 5.92M15 6.115H0' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
    --icon-inver: invert(0);
    --color-trade: 255, 255, 255;
}

@mixin mode-light {
    // Specific
    --color-logo: #{$theme-blue};
    --color-header-bg: #{$theme-white};
    --color-trustpilot: #{$theme-white};
    --color-header-icon: #{$theme-blue-light2};
    --color-header-icon-hover: #{$theme-blue-dark3};
    --color-menu-background: #{$theme-off-white};
    --color-checkout-background: #{$theme-off-white};
    --color-scrollbar-track: #{$theme-grey-light};
    --color-scrollbar-thumb: #{$theme-grey};
    --color-checkout-message-info-background: #{$theme-grey-dark3};
    --color-checkout-message-info: #{$theme-grey-dark4};

    // Generic
    --color-blue-primary: #{$theme-blue};
    --color-blue-primary-reverse: #{$theme-blue-dark3};
    --color-blue-secondary: #{$theme-white};
    --color-blue-secondary-reverse: #{$theme-blue-dark3};
    --color-blue-secondary2: #{$theme-off-white};
    --color-blue-secondary3: #{$theme-grey-light};
    --color-blue-tertiary: #{$theme-white};
    --color-blue-tertiary2: #{$theme-off-white};
    --color-blue-tertiary3: #{$theme-white};
    --color-text1: #{$theme-white};
    --color-text2: #{$theme-grey-dark};
    --color-text3: #{$theme-blue-dark3};
    --color-text4: #{$theme-grey};
    --color-text5: #{$theme-grey-dark};
    --color-text6: #{$theme-blue-light1};
    --color-text7: #{$theme-grey-dark};
    --color-text8: #{$theme-grey-dark};
    --color-border: #{$theme-grey-light};
    --color-border2: #{$theme-grey-light};
    --color-border3: #{$theme-grey};
    --color-border4: transparent;
    --color-border5: #{$theme-grey};
    --color-border6: #{$theme-grey-light};
    --color-border7: #c4c4c4;
    --color-border8: #c4c4c4;
    --color-border9: #{$theme-grey-light};
    --bg-color-edit: #c4c4c4;
    --color-input: #{$theme-grey-light};
    --color-input-reverse: #{$theme-blue-dark1};
    --color-input2: #{$theme-grey-light};
    --color-input3: #{$theme-white};
    --color-input4: #{$theme-grey-light};
    --color-input-search: #{$theme-blue-dark3};
    --color-icon: #{$theme-grey};
    --brightness-light: brightness(0.6);
    --invert-light: invert(1);
    --transparent: rgba(255, 255, 255, 0);
    --transparent2: rgba(255, 255, 255, 0);

    // Gradients
    --gradient-primary: #{$theme-white};
    --gradient-secondary: #{$theme-white};
    --gradient-fade: #{$theme-gradient-white-fade};
    --gradient-fade2: #{$theme-gradient-white-fade2};

    --box-gradient-blue: #{$theme-box-gradient-blue-light};
    --box-gradient-green: #{$theme-box-gradient-green-light};
    --box-gradient-purple: #{$theme-box-gradient-purple-light};

    // Images
    --arrow-left-primary: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.902 1L1.605 6.296m5.315 5.112L1 5.488m.92.805h15' stroke='%230b0d15' stroke-width='2'/%3E%3C/svg%3E");
    --arrow-right-primary: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.018 11.408l5.296-5.297M10 1l5.92 5.92M15 6.115H0' stroke='%230b0d15' stroke-width='2'/%3E%3C/svg%3E");
    --chevron-down-primary: url(../images/icons/blue/chevron-down.svg);
    --chevron-down-secondary: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 3 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7.071l7.071 7.071 7.071-7.071' stroke='%230b0d15' stroke-width='2'/%3E%3C/svg%3E");
    --icon-x-primary: url(../images/icons/blue/x.svg);
    --icon-x-secondary: url(../images/icons/grey/x2.svg);
    --icon-plus-primary: url(../images/icons/blue/plus2.svg);
    --icon-plus-primary2: url(../images/icons/blue/plus3.svg);
    --icon-plus-secondary: url(../images/icons/grey/plus.svg);
    --icon-list-tick: url(../images/icons/blue/list-tick.svg);
    --icon-save-cart: url(../images/icons/grey/save-cart.svg);

    //gradient images
    --gradient-image-account: url('../images/gradients/light.png');

    //offers page
    --color-offer: #0b0d15;
    --color-bg-offer: #{$theme-grey-light};
    --color-bg-tag: #0b0d15;

    //checkout process bar
    --bg-processbar: rgba(0, 0, 0, 0.05);
    --color-processbar: #000;
    --icon-overview: url(../images/icons/grey/overview.png);
    --icon-order: url("data:image/svg+xml,%3Csvg width='19' height='21' viewBox='0 0 19 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4.8L3.81481 1H15.0741L17.8889 4.8M1 4.8V18.1C1 18.6039 1.19771 19.0872 1.54963 19.4435C1.90155 19.7998 2.37885 20 2.87654 20H16.0123C16.51 20 16.9873 19.7998 17.3393 19.4435C17.6912 19.0872 17.8889 18.6039 17.8889 18.1V4.8M1 4.8H17.8889M13.1975 8.6C13.1975 9.60782 12.8021 10.5744 12.0983 11.287C11.3944 11.9996 10.4398 12.4 9.44444 12.4C8.44906 12.4 7.49445 11.9996 6.79061 11.287C6.08677 10.5744 5.69136 9.60782 5.69136 8.6' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-return: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 7L1 12M1 12L6 17M1 12H13C14.0609 12 15.0783 11.5786 15.8284 10.8284C16.5786 10.0783 17 9.06087 17 8V1' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-rewards: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-download: url("data:image/svg+xml,%3Csvg width='21' height='17' viewBox='0 0 21 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.04966 12.6688L10.5022 16M10.5022 16L13.9547 12.6688M10.5022 16V8.50485M18.1668 13.5766C18.9172 13.0674 19.4799 12.3408 19.7733 11.5022C20.0667 10.6636 20.0755 9.75656 19.7984 8.91278C19.5214 8.06899 18.9728 7.33235 18.2324 6.80975C17.4921 6.28715 16.5983 6.00578 15.681 6.00647H14.5934C14.3338 5.03033 13.8481 4.12373 13.1728 3.35492C12.4975 2.58611 11.6503 1.97513 10.6948 1.56797C9.73941 1.16081 8.70069 0.968087 7.65689 1.0043C6.61309 1.04052 5.59141 1.30474 4.66876 1.77706C3.7461 2.24939 2.94653 2.91751 2.33022 3.73113C1.71391 4.54476 1.29693 5.48268 1.11068 6.47429C0.924421 7.4659 0.97374 8.48536 1.25492 9.45592C1.5361 10.4265 2.04182 11.3228 2.734 12.0775' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-wishlist: url("data:image/svg+xml,%3Csvg width='24' height='22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.006 6.982l1 .028a1 1 0 000-.074l-1 .046zm10.708-3.036l-.874.485a1 1 0 001.749 0l-.875-.485zm10.708 3.036l-.999-.046a.979.979 0 000 .074l1-.028zM11.714 21l-.493.87a1 1 0 00.986 0l-.493-.87zM2.005 6.936A4.809 4.809 0 012 6.714H0c0 .105.002.21.007.314l1.998-.092zM2 6.714A4.714 4.714 0 016.714 2V0A6.714 6.714 0 000 6.714h2zM6.714 2c1.774 0 3.32.979 4.126 2.43l1.749-.97A6.713 6.713 0 006.714 0v2zm5.875 2.43A4.713 4.713 0 0116.714 2V0a6.713 6.713 0 00-5.874 3.46l1.749.97zM16.714 2a4.714 4.714 0 014.715 4.714h2A6.714 6.714 0 0016.713 0v2zm4.715 4.714c0 .075-.002.148-.006.222l1.998.092a6.94 6.94 0 00.008-.314h-2zm-.006.296c.004.133.006.272.006.419h2c0-.165-.003-.323-.007-.476l-2 .057zm.006.419c0 3.296-2.388 6.434-5.034 8.859a32.523 32.523 0 01-5.069 3.78l-.082.049a2.011 2.011 0 00-.019.01l-.004.003.493.87.494.87.003-.002.008-.005a2.644 2.644 0 00.124-.072 32.139 32.139 0 001.61-1.032 34.538 34.538 0 003.794-2.997c2.712-2.486 5.681-6.135 5.681-10.333h-2zM11.713 21a217.743 217.743 0 01.494-.87l-.005-.002c-.003-.003-.01-.006-.019-.011l-.082-.048a30.295 30.295 0 01-1.498-.96 32.528 32.528 0 01-3.571-2.821C4.388 13.863 2 10.725 2 7.428H0c0 4.199 2.97 7.848 5.681 10.334a34.529 34.529 0 005.404 4.029 11.692 11.692 0 00.124.072l.008.005.002.001h.001l.494-.869zM2 7.429c0-.147.002-.286.006-.419l-2-.057A23.97 23.97 0 000 7.43h2z' fill='%23000000'/%3E%3C/svg%3E%0A");
    --icon-address-book: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.33333 19V10H11.6667V19M1 7.3L9 1L17 7.3V17.2C17 17.6774 16.8127 18.1352 16.4793 18.4728C16.1459 18.8104 15.6937 19 15.2222 19H2.77778C2.30628 19 1.8541 18.8104 1.5207 18.4728C1.1873 18.1352 1 17.6774 1 17.2V7.3Z' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-account: url("data:image/svg+xml,%3Csvg width='17' height='20' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Ccircle cx='8.33311' cy='4.68955' r='3.68955' stroke='%23000000' stroke-linecap='round'/%3E%3Cpath d='M15.8966 18.5253C15.8966 15.1377 12.5619 12.3914 8.44828 12.3914C4.33471 12.3914 1 15.1377 1 18.5253' stroke='%23000000' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-payment: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.5H18M2.54545 1H16.4545C17.3081 1 18 1.67157 18 2.5V11.5C18 12.3284 17.3081 13 16.4545 13H2.54545C1.69192 13 1 12.3284 1 11.5V2.5C1 1.67157 1.69192 1 2.54545 1Z' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-subcriptions: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 2.75C19 1.7875 18.19 1 17.2 1H2.8C1.81 1 1 1.7875 1 2.75M19 2.75V13.25C19 14.2125 18.19 15 17.2 15H2.8C1.81 15 1 14.2125 1 13.25V2.75M19 2.75L10 8.875L1 2.75' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-store-credit: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.78711 9.96289L8.82617 11.0371C8.82617 11.5384 8.72526 11.9372 8.52344 12.2334H12.1025L12.0977 13H7.05859V12.2334H7.43457C7.56478 12.2041 7.67057 12.0837 7.75195 11.8721C7.83333 11.6572 7.87728 11.3936 7.88379 11.0811V11.042L7.84473 9.96289H7.03906V9.19629H7.82031L7.77637 7.92676C7.77637 7.27897 7.97493 6.76139 8.37207 6.37402C8.77246 5.98665 9.29818 5.79297 9.94922 5.79297C10.5677 5.79297 11.0576 5.96549 11.4189 6.31055C11.7803 6.6556 11.9609 7.11621 11.9609 7.69238H11.0283C11.0283 7.34408 10.9242 7.06901 10.7158 6.86719C10.5107 6.66536 10.2275 6.56445 9.86621 6.56445C9.52441 6.56445 9.24772 6.68978 9.03613 6.94043C8.82454 7.19108 8.71875 7.51986 8.71875 7.92676L8.7627 9.19629H10.3203V9.96289H8.78711Z' fill='%23000000'/%3E%3Ccircle cx='9.5' cy='9.5' r='9' stroke='%23000000'/%3E%3C/svg%3E%0A");
    --icon-download-stock: url("data:image/svg+xml,%3Csvg width='16' height='19' viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M13.1516 1H3C1.89543 1 1 1.89543 1 3V16.4536C1 17.5582 1.89543 18.4536 3 18.4536H10.7931C11.3189 18.4536 11.8235 18.2466 12.1978 17.8773L14.5563 15.5503C14.9372 15.1745 15.1516 14.6617 15.1516 14.1266V3C15.1516 1.89543 14.2562 1 13.1516 1Z' stroke='%23000000'/%3E%3Cpath d='M11.3776 18.4536V16.6799C11.3776 15.5753 12.273 14.6799 13.3776 14.6799H15.1513' stroke='%23000000'/%3E%3Cpath d='M4.06616 4.77378H11.8495' stroke='%23000000' stroke-linecap='round'/%3E%3Cpath d='M4.06616 7.13239H11.8495' stroke='%23000000' stroke-linecap='round'/%3E%3Cpath d='M4.06616 9.49093H11.8495' stroke='%23000000' stroke-linecap='round'/%3E%3Cpath d='M4.06616 11.8495H9.01922' stroke='%23000000' stroke-linecap='round'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-quotes: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Crect x='0.5' y='0.5' width='17' height='17' rx='2.5' stroke='%23000000'/%3E%3Cpath d='M0.5 12.5H6.5V17.5H3C1.61929 17.5 0.5 16.3807 0.5 15V12.5Z' stroke='%23000000'/%3E%3Crect x='0.5' y='7.5' width='6' height='5' stroke='%23000000'/%3E%3Cpath d='M12.5 7.5H17.5V15C17.5 16.3807 16.3807 17.5 15 17.5H12.5V7.5Z' stroke='%23000000'/%3E%3Crect x='6.5' y='12.5' width='6' height='5' stroke='%23000000'/%3E%3Crect x='6.5' y='7.5' width='6' height='5' stroke='%23000000'/%3E%3C/g%3E%3C/svg%3E%0A");
    --icon-my-save-cart: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H4.63636L7.07273 13.9049C7.15586 14.3486 7.38355 14.7471 7.71595 15.0308C8.04835 15.3145 8.46427 15.4652 8.89091 15.4565H17.7273C18.1539 15.4652 18.5698 15.3145 18.9022 15.0308C19.2346 14.7471 19.4623 14.3486 19.5455 13.9049L21 5.81884H5.54545M18.2549 18.7609C18.2549 19.4452 17.7282 20 17.0784 20C16.4287 20 15.902 19.4452 15.902 18.7609C15.902 18.0765 16.4287 17.5217 17.0784 17.5217C17.7282 17.5217 18.2549 18.0765 18.2549 18.7609ZM11.1961 18.7609C11.1961 19.4452 10.6694 20 10.0196 20C9.36986 20 8.84314 19.4452 8.84314 18.7609C8.84314 18.0765 9.36986 17.5217 10.0196 17.5217C10.6694 17.5217 11.1961 18.0765 11.1961 18.7609Z' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-logout: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --icon-arrow-right: url("data:image/svg+xml,%3Csvg width='17' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.018 11.408l5.296-5.297M10 1l5.92 5.92M15 6.115H0' stroke='%23000000' stroke-width='2'/%3E%3C/svg%3E%0A");
    --icon-inver: invert(1);
    --color-trade: 255, 255, 255;
}

// Default to dark mode
:root {
    @include mode-dark;
}

.mode-light {
    @include mode-light;
}

@media (prefers-color-scheme: dark) {
    :root {
        @include mode-dark;
    }

    .mode-light {
        @include mode-light;
    }
}

@media (prefers-color-scheme: light) {
    :root {
        @include mode-light;
    }

    .mode-dark {
        @include mode-dark;
    }
}
